import { useTimeout }       from "@mantine/hooks";
import dynamic              from "next/dynamic";
import { useRouter }        from "next/router";
import React, { useEffect } from "react";

import {
	useGetInterestialPopup,
	useGetLoginPopup,
	useSetInterestialPopup
} from "_common/hooks/global";
import { isPageRoute } from "_common/utils";

const InterestialPopup = dynamic ( () => import ( "_components/molecules/interestialPopup" ) );

const DownloadPopup = () => {
	const router              = useRouter ();
	const loginPopup          = useGetLoginPopup ();
	const setInterestialPopup = useSetInterestialPopup ();
	const interestialPopup    = useGetInterestialPopup ();
	const isHomePage          = isPageRoute ( { page: "home", router } );

	const { start, clear } = useTimeout ( () => {
		setInterestialPopup ( true );
	}, 10000 );

	useEffect ( () => {
		const status = localStorage.getItem ( "hasShownDownloadAppModal" ) === "true";

		if ( isHomePage && !status )
			start ();
		else
			clear ();
	}, [ router.pathname ] );

	const showInterestialPopup = interestialPopup && !loginPopup;

	return (
		<InterestialPopup
			onClose = { () => setInterestialPopup ( false ) }
			popup   = { showInterestialPopup }
		/>
	);
};

export default DownloadPopup;
